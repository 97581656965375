import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Cookies from "js-cookie";
import "./cookieBanner.css";

const useStyles = makeStyles((theme) => ({
  cookieBannerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1170px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      textAlign: "center",
    },
    "& a, a:visited, a:focus, a:active": {
      color: "#17af66",
      textDecoration: "underline",
    },
    "& a:hover": {
      color: "#107947",
    },
  },
  manageCookiesButton: {
    color: "#103b41",
    backgroundColor: "transparent",
    marginRight: 0,
    whiteSpace: "nowrap",
    border: "#17af66 1px solid",
    borderRadius: "4px",
    cursor: "pointer",
    display: "inline-block",
    padding: "10px 20px",
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease",
    transitionProperty:
      "background,background-color,background-position,border,box-shadow,color,opacity,transform,visibility",
    textDecoration: "none",
    outline: 0,
    fontWeight: 500,
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  cookieBannerFlexButtons: {
    display: "flex",
    gap: "12px",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "@media (max-width: 480px)": {
      justifyContent: "center",
    },
  },
  cookieChoiceContainer: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 0 15px 5px rgb(0 0 0 / 30%)",
    fontSize: ".9em",
    fontFamily: ["Clarity City", "sans-serif"].join(","),
    lineHeight: 1.5,
    left: "50%",
    maxHeight: "80vh",
    maxWidth: "560px",
    overflowY: "auto",
    padding: "20px 30px",
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    width: "95vw",
    zIndex: 2090,
    boxSizing: "border-box",
  },
  cookieChoiceClose: {
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "15px",
    padding: "15px",
    position: "absolute",
    right: "0",
    top: "0",
    color: "#404040",
  },
  headline: {
    fontSize: "1.3em",
    color: "#17af66",
    fontWeight: 500,
    marginBottom: "10px",
    marginTop: "0",
    fontStyle: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-.035em",
    lineHeight: "1.5 !important",
  },
  cookieText: {
    marginTop: "0",
    marginBottom: "20px",
    overflowWrap: "break-word",
  },
  divider: {
    backgroundColor: "#dadada",
    border: "0",
    height: "1px",
    margin: "1.5em 0",
    maxWidth: "1170px",
    boxSizing: "content-box",
    overflow: "visible",
  },
  cookieChoiceTrigger: {
    border: "#dadada 1px solid",
    borderTop: "none",
    padding: "15px",
    position: "relative",
  },
  cookiesLabel: {
    cursor: "pointer",
    display: "inline-block",
    fontWeight: 700,
    maxWidth: "80%",
    marginLeft: "4px",
    color: "#404040 !important",
    marginBottom: "0px !important",
    lineHeight: "unset",

    "&:before": {
      border: "2px solid #17af66",
      borderRadius: "5px",
      backgroundColor: "#FFF",
      content: '""',
      cursor: "pointer",
      display: "block",
      float: "left",
      height: "15px",
      marginRight: "5px",
      position: "relative",
      width: "15px",
      zIndex: 2005,
      boxSizing: "border-box",
    },
  },
  checked: {
    "&:before": {
      backgroundColor: "#17af66",
      boxShadow: "inset 0 0 0 3px #fff",
    },
  },
  cookieChoiceTriggerInfo: {
    cursor: "pointer",
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  cookiesDescription: {
    marginBottom: "0",
    marginTop: "5px",
    "& > span:last-of-type > a": {
      marginBottom: "5px",
    },
  },
  overlayBg: {
    backgroundColor: "rgba(0,0,0,.5)",
    bottom: "0",
    display: "block",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: 2050,
  },
  overlayIncreasedZIndex: {
    zIndex: 2060,
  },
  privacyDesc: {
    marginTop: 0,
    marginBottom: "20px",

    "& > a, & > a:visited, & > a:focus, & > a:active": {
      color: "#17af66",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease",
      transitionProperty:
        "background,background-color,background-position,border,box-shadow,color,opacity,transform,visibility",
      textDecoration: "none",
    },
    "& > a:hover": {
      color: "#107947",
    },
  },
  parentFontSize: {
    fontSize: "14.5px",
    letterSpacing: "-.1px",
    "-webkit-font-smoothing": "subpixel-antialiased !important",
  },
  secondary: {
    "&:hover": {
      boxShadow: "none",
    },
    "&:not(:hover)": {
      borderColor: "#FFFFFF",
      boxShadow: "none",
    },
  },
  vendorContainer: {
    border: "1px solid #e5e9ee !important",
    marginRight: 0,
    marginTop: "15px !important",
    position: "relative",
    width: "100% !important",
    boxSizing: "border-box",
  },
  vendorLinkIcon: {
    height: 20,
    width: 20,
    filter:
      "invert(54%) sepia(72%) saturate(503%) hue-rotate(99deg) brightness(87%) contrast(89%)",
    position: "absolute",
    right: 20,
  },
}));

export default function CookieBanner() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [showInfo, setShowInfo] = useState({
    essential: false,
    performance: false,
    functional: false,
    social: false,
  });
  const [cookieChecked, setCookieChecked] = useState({
    essential: true,
    performance: false,
    functional: false,
    social: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [cookiesInfoApi, setCookiesInfoApi] = useState({
    s3t_cookie_banner_text: "",
    s3t_cookie_privacy_desc: "",
    s3t_cookie_essential_desc: "",
    s3t_cookie_performance_desc: "",
    s3t_cookie_functional_desc: "",
    s3t_cookie_social_desc: "",
  });
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [handleLegacyCase, sethandleLegacyCase] = useState(true);
  const [env, setEnv] = useState("");

  function checkNonEssential() {
    if (Cookies.get("3t-cookies-performance") === "1") {
      handleChecked("performance");
    }
    if (Cookies.get("3t-cookies-functional") === "1") {
      handleChecked("functional");
    }
    if (Cookies.get("3t-cookies-social") === "1") {
      handleChecked("social");
    }
  }

  useEffect(() => {
    setEnv(".robomongo.org");
    manageLegacyCookies();

    getWpData();
    if (Cookies.get("3t-cookies-essential") !== "1") {
      setShowCookieBanner(true);
    }

    checkNonEssential();
  }, []);

  useEffect(() => {
    if (location.hash === "#cookie-settings") {
      setShowModal(true);
    }
  }, [location.hash]);

  useEffect(() => {
    checkNonEssential();
  }, [showModal]);

  async function getWpData() {
    await axios("https://studio3t.com/wp-json/license-manager/cookie-data")
      .then((response) => {
        setCookiesInfoApi(response.data);
      })
      .catch((error) => {
        console.log("Error fetching WP data: ", error);
      });
  }

  function manageLegacyCookies() {
    if (
      Cookies.get("3t-cookie-accept") !== undefined ||
      Cookies.get("3t-cookie-acccept") !== undefined ||
      Cookies.get("cookie_notice_accepted") !== undefined
    ) {
      Cookies.remove("3t-cookie-accept");
      Cookies.remove("3t-cookie-accept", {
        domain: env,
      });
      Cookies.remove("3t-cookie-acccept");
      Cookies.remove("3t-cookie-acccept", {
        domain: env,
      });
      Cookies.remove("cookie_notice_accepted");
      Cookies.remove("cookie_notice_accepted", {
        domain: env,
      });
      handleAcceptAllCookies();
    } else {
      sethandleLegacyCase(false);
    }
  }

  function handleManageCookiesButton() {
    setShowModal(!showModal);

    if (location.hash === "#cookie-settings") {
      deleteCookies();
      navigate(location.pathname);
    }
  }

  function handleInfoClick(cookieName) {
    setShowInfo({
      ...showInfo,
      [cookieName]: !showInfo[cookieName],
    });
  }
  function handleChecked(cookieName) {
    setCookieChecked((cookieChecked) => {
      return {
        ...cookieChecked,
        [cookieName]: !cookieChecked[cookieName],
      };
    });
  }
  // Set essential cookies
  function setEssentialCookies() {
    Cookies.set("3t-cookies-essential", "1", {
      expires: 365,
      domain: env,
      sameSite: "lax",
    });
  }

  // Set essential cookies
  function setPerformanceCookies() {
    Cookies.set("3t-cookies-performance", "1", {
      expires: 365,
      domain: env,
      sameSite: "lax",
    });
  }

  // Set essential cookies
  function setFunctionalCookies() {
    Cookies.set("3t-cookies-functional", "1", {
      expires: 365,
      domain: env,
      sameSite: "lax",
    });
  }

  // Set essential cookies
  function setSocialCookies() {
    Cookies.set("3t-cookies-social", "1", {
      expires: 365,
      domain: env,
      sameSite: "lax",
    });
  }

  function removeAllCookies() {
    // Remove cookies
    var cookies = document.cookie.split("; ");

    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }

    // Clear local storage
    localStorage.clear();
  }

  function handleAcceptAllCookies() {
    if (
      !showCookieBanner &&
      !handleLegacyCase &&
      location.hash !== "#cookie-settings"
    ) {
      return;
    }
    removeAllCookies();
    setCookieChecked({
      essential: true,
      performance: true,
      functional: true,
      social: true,
    });
    setEssentialCookies();
    setPerformanceCookies();
    setFunctionalCookies();
    setSocialCookies();
    setShowCookieBanner(false);
    setShowModal(false);

    if (location.hash === "#cookie-settings") {
      deleteCookies();
      navigate(location.pathname);
    }
    window.location.reload(false);
  }

  function deleteCookies() {
    if (
      Cookies.get("3t-cookies-performance") === "1" &&
      !cookieChecked.performance
    ) {
      Cookies.remove("3t-cookies-performance", {
        domain: env,
      });
    }

    if (
      Cookies.get("3t-cookies-functional") === "1" &&
      !cookieChecked.functional
    ) {
      Cookies.remove("3t-cookies-functional", {
        domain: env,
      });
    }

    if (Cookies.get("3t-cookies-social") === "1" && !cookieChecked.social) {
      Cookies.remove("3t-cookies-social", {
        domain: env,
      });
    }
  }

  function handleDeclineAll() {
    if (!showCookieBanner && !handleLegacyCase) {
      return;
    }
    removeAllCookies();

    setCookieChecked({
      essential: true,
      performance: false,
      functional: false,
      social: false,
    });
    setEssentialCookies();
    setShowCookieBanner(false);
    window.location.reload(false);
  }

  function handleSelectedCookies() {
    removeAllCookies();
    setEssentialCookies();

    if (cookieChecked.functional) {
      setFunctionalCookies();
    }
    if (cookieChecked.performance) {
      setPerformanceCookies();
    }
    if (cookieChecked.social) setSocialCookies();

    setShowCookieBanner(false);
    setShowModal(false);

    if (location.hash === "#cookie-settings") {
      deleteCookies();
      navigate(location.pathname);
    }
    window.location.reload(false);
  }

  function checkVendors(vendorType) {
    return vendorType.map((vendor, index) => {
      if (vendor.used_by.includes("robomongo.org")) {
        return (
          <span key={index}>
            <a
              className={clsx(
                classes.manageCookiesButton,
                classes.vendorContainer
              )}
              rel="noreferrer"
              target="_blank"
              href={vendor.link}
            >
              {vendor.name}
              <img
                alt="link-icon"
                className={classes.vendorLinkIcon}
                src={
                  "https://studio3t.com/wp-content/themes/s3t-2020/images/link.svg"
                }
              />
            </a>
          </span>
        );
      }
    });
  }

  if (!showCookieBanner && !showModal) return null;

  return (
    <>
      <div
        className={clsx(
          classes.overlayBg,
          showModal && classes.overlayIncreasedZIndex
        )}
        onClick={() => {
          if (!showModal) {
            return;
          }

          handleManageCookiesButton();
        }}
      ></div>

      {showModal && (
        <div className={classes.parentFontSize}>
          <Box className={classes.cookieChoiceContainer}>
            <Box
              className={classes.cookieChoiceClose}
              onClick={handleManageCookiesButton}
            >
              ✕
            </Box>
            <Box>
              <h4 className={classes.headline}>Privacy Preference Center</h4>

              <p className={classes.privacyDesc}>
                {ReactHtmlParser(cookiesInfoApi.s3t_cookie_privacy_desc)}
              </p>
            </Box>
            <Box
              style={{
                backgroundColor: "#17af66",
                color: "#fff",
                fontWeight: 500,
              }}
              marginLeft={"0 !important"}
              marginRight={"15px !important"}
              marginTop={"0 !important"}
              className={classes.manageCookiesButton}
              onClick={handleAcceptAllCookies}
            >
              Accept all cookies
            </Box>
            <hr className={classes.divider} />
            <h4 className={classes.headline}>Manage consent preferences</h4>
            <Box
              className={classes.cookieChoiceTrigger}
              style={{
                borderTop: "#dadada 1px solid",
              }}
            >
              <label className={clsx(classes.cookiesLabel, classes.checked)}>
                Essential cookies (Always active)
              </label>
              {showInfo.essential && (
                <p className={classes.cookiesDescription}>
                  {cookiesInfoApi.s3t_cookie_essential_desc}
                </p>
              )}
              <img
                className={classes.cookieChoiceTriggerInfo}
                src="https://studio3t.com/wp-content/themes/s3t-2020/images/info.svg"
                width="16"
                height="16"
                loading="lazy"
                data-target="trigger-necessary"
                alt="info"
                onClick={() => handleInfoClick("essential")}
              ></img>
            </Box>
            <Box className={classes.cookieChoiceTrigger}>
              <label
                className={clsx(
                  classes.cookiesLabel,
                  cookieChecked.performance && classes.checked
                )}
                onClick={() => handleChecked("performance")}
              >
                Analytics and marketing cookies
              </label>
              {showInfo.performance && (
                <p className={classes.cookiesDescription}>
                  {cookiesInfoApi.s3t_cookie_performance_desc}
                  {checkVendors(cookiesInfoApi.s3t_performance_vendors)}
                </p>
              )}
              <img
                className={classes.cookieChoiceTriggerInfo}
                src="https://studio3t.com/wp-content/themes/s3t-2020/images/info.svg"
                width="16"
                height="16"
                loading="lazy"
                data-target="trigger-necessary"
                alt="info"
                onClick={() => handleInfoClick("performance")}
              ></img>
            </Box>
            <Box className={classes.cookieChoiceTrigger}>
              <label
                className={clsx(
                  classes.cookiesLabel,
                  cookieChecked.functional && classes.checked
                )}
                onClick={() => handleChecked("functional")}
              >
                Functional cookies
              </label>
              {showInfo.functional && (
                <p className={classes.cookiesDescription}>
                  {cookiesInfoApi.s3t_cookie_functional_desc}
                  {checkVendors(cookiesInfoApi.s3t_functional_vendors)}
                </p>
              )}
              <img
                className={classes.cookieChoiceTriggerInfo}
                src="https://studio3t.com/wp-content/themes/s3t-2020/images/info.svg"
                width="16"
                height="16"
                loading="lazy"
                data-target="trigger-necessary"
                alt="info"
                onClick={() => handleInfoClick("functional")}
              ></img>
            </Box>
            <Box className={classes.cookieChoiceTrigger}>
              <label
                className={clsx(
                  classes.cookiesLabel,
                  cookieChecked.social && classes.checked
                )}
                onClick={() => handleChecked("social")}
              >
                Social Media cookies
              </label>
              <img
                className={classes.cookieChoiceTriggerInfo}
                src="https://studio3t.com/wp-content/themes/s3t-2020/images/info.svg"
                width="16"
                height="16"
                loading="lazy"
                data-target="trigger-necessary"
                alt="info"
                onClick={() => handleInfoClick("social")}
              ></img>
              {showInfo.social && (
                <p className={classes.cookiesDescription}>
                  {cookiesInfoApi.s3t_cookie_social_desc}
                  {checkVendors(cookiesInfoApi.s3t_social_vendors)}
                </p>
              )}
            </Box>
            <Box
              style={{
                marginTop: "20px",
                marginLeft: 0,
                marginRight: "15px",
              }}
              className={classes.manageCookiesButton}
              onClick={handleSelectedCookies}
            >
              Accept selected
            </Box>
          </Box>
        </div>
      )}
      {cookiesInfoApi.s3t_cookie_banner_text &&
        location.hash !== "#cookie-settings" &&
        !showModal && (
          <div className={classes.parentFontSize}>
            <Box className={classes.cookieChoiceContainer}>
              <Box>
                <h4 className={classes.headline}>We value your privacy</h4>
                <p className={classes.privacyDesc}>
                  {ReactHtmlParser(cookiesInfoApi.s3t_cookie_banner_text)}
                </p>
              </Box>
              <Box className={classes.cookieBannerFlexButtons}>
                <Box
                  className={classes.manageCookiesButton}
                  style={{
                    backgroundColor: "#17af66",
                    color: "#fff",
                    fontWeight: 500,
                  }}
                  onClick={handleAcceptAllCookies}
                >
                  Accept all
                </Box>
                <Box
                  className={clsx(
                    classes.manageCookiesButton,
                    classes.secondary
                  )}
                  onClick={handleDeclineAll}
                >
                  Decline all
                </Box>
                <Box
                  className={clsx(
                    classes.manageCookiesButton,
                    classes.secondary
                  )}
                  onClick={handleManageCookiesButton}
                >
                  Manage cookies
                </Box>
              </Box>
            </Box>
          </div>
        )}
    </>
  );
}
